.furniture-care {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .FurnitureCollection {
    padding-top: 90px;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
}

.footer {
  margin-top: auto;
}

@media (max-width: 1024px) {
  .furniture-care {
    margin-top: 25px !important;
    gap: 20px;
  }
}
