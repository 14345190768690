.furniture-card {
  width: 100%;
  min-height: 350px;
  background-size: cover;
  cursor: pointer;
  background-position: center;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  @media (max-width: 480px) {
    min-height: unset;
    height: 250px;
  }

  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    bottom: 20px;
    left: 50px;
    color: #fff;

    @media (max-width: 768px) {
      left: 20px;
    }

    .title {
      font-style: normal;
      font-weight: 400;
      line-height: 90%;
      max-width: 430px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 50px;
      }
    }
  }

  .furniture-card-button {
    padding: 20px 50px;
  }
}
