.footer {
  background-color: var(--color-main-black);

  .mira {
    color: var(--color-main-white);
    padding: 50px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex: 1;

    @media (max-width: 1280px) {
      padding: 50px 40px;
    }

    @media (max-width: 768px) {
      padding: 70px 10px 100px;
    }

    .footer-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      width: 100%;
      flex: 1;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 50px;
      }
    }

    .footer-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 56px;
      justify-content: space-between;

      .footer-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
          color: var(--color-main-white);
          font-size: 50px;

          @media (max-width: 768px) {
            font-size: 30px;
          }
        }
      }

      .text {
        color: var(--color-main-white);
        text-decoration: unset;
      }
    }

    .footer-column-nav {
      align-items: flex-start;
      @media (max-width: 1440px) {
        align-items: flex-end;
      }

      .nav-text {
        text-align: left;
        width: 150px;

        &:hover {
          color: var(--color-main-pink) !important;
        }
      }
    }

    .footer-social {
      justify-content: flex-end;
      align-items: flex-end;

      .social-icons svg:hover {
        color: var(--color-main-pink) !important;
      }
      .war {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }
  }
}
