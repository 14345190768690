.gallery-grid {
  .ant-image {
    width: 100%;
  }
  .img {
    width: 100%;
    lineheight: 160px;
    textalign: center;
    color: var(--color-main-black);
    background-color: var(--color-main-white);
    margin: 0;
    @media (max-width: 768px) {
      height: 317px;
    }
    object-fit: cover;
  }
}
