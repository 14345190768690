.custom-drawer {
  background: unset !important;
  .ant-drawer-body {
    padding: 0 !important;
  }

  .war {
    background: rgba(0, 0, 0, 0.85);
    min-height: 100%;
  }
  .drawer-content {
    max-width: 1320px;
    margin: 0 auto;
    color: var(--color-main-white);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1280px) {
      padding: 0 25px;
    }

    @media (max-width: 768px) {
      width: unset;
      gap: 20px;
      padding: 0 20px;
    }

    .drawer-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 50px 0;

      @media (max-width: 768px) {
        padding: 25px 0 5px 0;
      }

      .logo {
        font-size: 32px;
      }

      .close-icon {
        font-size: 24px;
        cursor: pointer;
        color: var(--color-main-white);
      }
    }

    .drawer-nav {
      display: flex;
      justify-content: flex-start;
      row-gap: 20px;
      width: 900px;
      column-gap: 50px;
      flex-wrap: wrap;
      flex-grow: 1;
      align-items: center;

      @media (max-width: 1024px) {
        width: unset;
      }

      @media (max-width: 768px) {
        width: unset;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      .title {
        color: var(--color-main-white);

        @media (max-width: 768px) {
          font-size: 50px;
        }

        &:hover {
          color: var(--color-main-pink);
        }

        &.active {
          color: var(--color-main-pink);
        }
      }
    }

    .drawer-footer {
      display: flex;
      justify-content: end;
      margin-bottom: 20px;

      @media (max-width: 480px) {
        justify-content: flex-start;
        padding: 0 0 20px 0;
      }

      .contact-icon {
        margin-left: 10px;
        font-size: 30px;
        color: var(--color-main-white);

        &:hover {
          color: var(--color-main-pink);
        }
      }
    }
  }
}
