.custom-slider {
  width: 100%;
  margin: 0 auto;
  position: relative;

  .slick-dots {
    left: 10px;
    bottom: 20px;
    text-align: left;
    width: unset;

    .slick-active {
      border-radius: 50%;
      border: 3px solid transparent;
      background-color: var(--color-main-pink);

      button:before {
        color: transparent;
      }
    }

    li {
      border-radius: 50%;
      border: 3px solid #c3c3c3;
    }
  }
}

.slide-container {
  transition: transform 0.3s ease-in-out;
  transform: scaleX(1);
}

.slide-container.current-slide {
  transform: scaleX(1);
}

.slide-container.next-slide {
  transform: scaleX(1.2);
  z-index: 2;
}

.slide-container.next-slide:hover {
  transform: scaleX(1.25);
}

.slide-container:not(.current-slide):not(.next-slide) {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  .slide-container.next-slide {
    transform: scaleX(1);
    z-index: 2;
  }

  .slide-container.next-slide:hover {
    transform: scaleX(1);
  }
}
