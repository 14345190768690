.search-container {
  display: flex;
  align-items: flex-end;
  position: relative;


  @media (max-width: 480px) {
    display: none;
  }

  .input-wrapper {
    overflow: hidden;
    width: 0;
    transition:
      width 0.4s ease,
      opacity 0.4s ease;
    opacity: 0;
  }

  .show {
    width: 210px;
    opacity: 1;
  }

  .search-input {
    border: none;
    height: 46px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    outline: none;
    border-radius: unset;
    width: 100%;
  }

  .search-input::placeholder {
    color: #aaa;
  }

  .icon-wrapper {
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition:
      background-color 0.4s ease,
      color 0.4s ease;
  }

  .icon {
    font-size: 24px;
    transition: color 0.4s ease;
  }

  .icon-wrapper.active {
    background-color: #333;
  }

  .icon-wrapper.active .icon {
    color: var(--color-main-white);
  }
}
