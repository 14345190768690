.project-card {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: height 0.5s ease-in-out;

  @media (max-width: 1024px) {
    padding: 20px;
    width: unset;
  }

  .content {
    position: relative;
    padding: 20px 50px;
    z-index: 2;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @media (max-width: 1024px) {
      flex-direction: column;
      height: 100%;
      padding: 0;
      align-items: flex-start;
    }

    .title {
      color: var(--color-main-white);
      font-style: normal;
      font-weight: 400;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 480px) {
        font-size: 50px;
      }
    }

    .text {
      color: var(--color-main-white);

      font-style: normal;
      font-weight: 400;
      line-height: 90%;
      text-transform: uppercase;
    }

    .project-button {
      padding: 30px 150px;

      @media (max-width: 1024px) {
        width: 100%;
        padding: 27px 0;
      }
    }
  }
}
