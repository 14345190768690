.FurnitureWithMenu {
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .left-column {
    flex: 1;
    display: flex;
    flex-direction: column;

    .card-wrapper {
      flex: 1;
      overflow: hidden;
      display: flex;
    }
  }

  .right-column {
    flex: 1;
    overflow: hidden;
    display: flex;
  }
}
