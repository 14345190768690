.contacts {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  gap: 50px;
}

.contacts-block {
  display: flex;
  justify-content: space-between;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-left: 50px;

    @media (max-width: 1280px) {
      padding: 25px 40px 0 25px;
    }

    @media (max-width: 768px) {
      width: unset;
      gap: 20px;
      margin: 0;
      padding: 20px 20px 0 20px;
    }

    @media (max-width: 478px) {
        padding: 10px;
    }


    .cont-section {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        font-size: 50px;
      }
    }
  }
  .contacts-image {
    width: 50%;
  }

  .contacts-image img {
    width: 100%;
  }

  .social-icons svg:hover {
    color: var(--color-main-pink) !important;
  }
}

.footer {
  margin-top: auto;
}

@media (max-width: 1280px) {
  .contacts-block {
    display: block;

    .wrapper {
      margin-bottom: 30px;
    }

    .contacts-image {
      width: 100%;
    }
  }
}

