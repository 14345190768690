.logo {
  .logo-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .logo-desktop {
      display: none;
    }
    .logo-mobile {
      display: block;
    }
  }
}
