.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.alignS {
  align-items: flex-start;
}

.alignE {
  align-items: flex-end;
}

.alignC {
  align-items: center;
}

.justify-s {
  justify-content: space-between;
}

.justify-fs {
  justify-content: flex-start;
}

.justify-c {
  justify-content: center;
}

.TextA-c {
  text-align: center;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-120 {
  padding-bottom: 120px;
}

.w-100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}
