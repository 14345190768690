.menu-card {
  width: 100%;
  cursor: pointer;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  .content {
    position: relative;
    z-index: 2;
    padding: 20px;
    min-height: 350px;
    color: #fff;
    text-align: left;

    .title {
      color: var(--color-main-pink);
      font-weight: 400;
      margin-bottom: 13px;

      @media (max-width: 768px) {
        font-size: 50px;
        margin-bottom: 30px;
      }
    }

    .items {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 30px;
        font-family: 'Memesique', sans-serif;
        font-size: 50px;
        font-weight: 400;
        font-style: normal;
        width: 300px;
        line-height: 90%;

        &:hover {
          color: var(--color-main-pink);
        }
      }
    }

    .catalog-button {
      margin-top: 20px;
    }

    .menu-card-button {
      color: white;

      .text {
        color: white;
      }
    }
  }
}
