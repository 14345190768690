.pull-down-hand {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;

  .hand-img {
    width: 24px;
    height: auto;
    animation: hand-bounce 2.5s infinite ease-in-out;
  }
}

@keyframes hand-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
