.filter-component {
  margin-right: auto;

  @media (max-width: 480px) {
    margin: 0;
    width: 100%;
  }
  .filter-header {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-main-white);
  }

  .catalog-filter-button {
    padding: 13px 100px;

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

.filter-drawer {
  .war {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    .close-icon {
      color: white;
      font-size: 20px;
    }
  }
}

.filter-buttons {
  position: static;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  left: 20px;
  width: 100%;
  padding-top: 20px;
  gap: 20px;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }

  .catalog-filter-button {
    padding: 10px 28px;

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

.filter-discount {
  border-top: 1px solid #6c6c6c;
  border-bottom: 1px solid #6c6c6c;
  padding: 24px 0;

  .discount-label {
    text-transform: uppercase;
    font-family: Memesique;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    margin-left: 20px;
  }
}

.ant-collapse {
  background-color: transparent !important;
}

.ant-collapse-header {
  color: var(--color-main-white) !important;
  font-size: 18px;
  font-weight: bold;
}

.ant-collapse-header .anticon {
  color: var(--color-main-white) !important;
}

.ant-checkbox-wrapper {
  color: var(--color-main-white) !important;
  font-size: 16px;
}

.ant-radio-wrapper {
  color: var(--color-main-white) !important;
  font-size: 16px;
}

.ant-drawer-content {
  background-color: rgba(0, 0, 0, 0.5);
}

.ant-drawer-header {
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: none;
}

.ant-drawer-title,
.ant-drawer-close {
  color: var(--color-main-white) !important;
}
