.ant-radio {
  color: '#fff';
  border: 3px solid #fff;

  .ant-radio-inner {
    background-color: transparent;
    width: 30px;
    height: 30px;
  }
  .ant-radio-inner:after {
    border: none;
  }
}

.ant-radio-wrapper {
  font-size: 20px;
  text-transform: lowercase;
}

.ant-radio-checked .ant-radio-inner {
  border: 8px solid #fff !important;
  background-color: transparent !important;
}

.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus .ant-radio-inner,
.ant-radio-checked:hover .ant-radio-inner {
  border-color: #fff !important;
  background-color: transparent !important;
  border: 4px solid #fff;
}

.ant-checkbox {
  color: '#fff';
  border: 3px solid #fff;
  border-radius: 0;
  .ant-checkbox-inner {
    background-color: transparent;
    border-radius: 0;
    width: 30px;
    height: 30px;
  }
  .ant-checkbox-inner:after {
    border: none;
  }
}

.ant-checkbox-wrapper {
  font-size: 20px;
  text-transform: lowercase;
}

.ant-checkbox-checked {
  border-radius: 0;
  .ant-checkbox-inner {
    border: 10px solid #fff !important;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus .ant-checkbox-inner,
.ant-checkbox-checked:hover .ant-checkbox-inner {
  border-color: #fff !important;
  background-color: transparent !important;
  border: 5px solid #fff;
}

.ant-collapse {
  .ant-collapse-item:hover {
    .ant-collapse-header-text {
      color: #ff1fa5;
    }
  }

  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
      margin: 0;
      font-family: Memesique;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      align-items: center;

      .anticon {
        font-size: 35px;
      }
    }
  }
}

.ant-slider {
  .ant-slider-track {
    background-color: #fff; /* Change the track color */
    height: 14px;
  }

  .ant-slider-track:hover {
    background-color: #ff1fa5;
  }

  .ant-slider-handle {
    border: 2px solid #ff1fa5; /* Change handle border color */
    background-color: #fff; /* Change handle background color */
    width: 24px; /* Change handle width */
    height: 24px; /* Change handle height */
    margin-top: -3px; /* Adjust handle position */
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }

  .ant-slider-handle:hover {
    .ant-slider-track {
      background-color: #ff1fa5;
    }
    background-color: #ff1fa5; /* Change handle color on hover */
    border-color: #ff1fa5; /* Change border color on hover */
  }

  .ant-slider-handle:before {
    display: none;
  }
  .ant-slider-handle:after {
    display: none;
  }

  .ant-slider-mark-text {
    color: #000; /* Change text color for marks */
  }
}

.ant-slider:hover {
  .ant-slider-track {
    background-color: #ff1fa5;
  }
}

.ant-spin-dot-holder {
  color: #ff1fa5 !important;
}

.anticon-close:hover {
  color: #ff1fa5 !important;
}

.ant-drawer-content-wrapper {
  height: unset !important;
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-input {
  /* color: white !important; */
  padding: 0;
}
