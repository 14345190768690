.recommended-container {
  display: flex;
  margin-bottom: 100px;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 1366px) {
    padding: 0 50px;
  }

  @media (max-width: 1280px) {
    padding: 0 25px;
  }

  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column-reverse;
    margin-bottom: 50px;
    gap: 20px;
  }

  .right-block {
    max-width: 100%;
    width: 80%;

    @media (max-width: 1440px) {
      max-width: 1024px;
      width: 66%;
    }


    @media (max-width: 768px) {
      width: unset;
    }
    @media (max-width: 480px) {
      width: unset;
    }
  }

  .left-block {
    max-width: 306px;
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    padding-left: 50px;

    @media (max-width: 768px) {
      padding: 0 10px;
      width: calc(100% - 20px);
      max-width: unset;
    }

    .description {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .title {
      color: var(--color-main-pink);
      font-family: 'Memesique', sans-serif;
      font-size: 40px;
      font-style: normal;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 480px) {
        font-size: 50px;
      }
    }

    .price {
      color: var(--color-main-black);
      font-family: 'Memesique', sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 480px) {
        font-size: 30px;
      }
    }

    .catalog-detail-btn {
      width: 100%;
      max-width: 300px;

      @media (max-width: 480px) {
        max-width: unset;
      }
    }
  }
}
