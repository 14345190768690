.recommendations {
  display: flex;
  flex-direction: column;
  gap: 64px;

  .header {
    gap: 0;
  }


  .content {

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }
  
  .btn {
    @media (max-width: 480px) {
      width: 100%;
    }
  }


}
