.designers {
  .designers-content {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @media (max-width: 480px) {
      justify-content: center;
      margin-bottom: 50px;
    }

    .designers-btn {
      padding: 20px 90px;
      @media (max-width: 480px) {
        margin: 0 10px;
        width: 100%;
      }
    }
  }

  .designers-slider {
    left: 50px;

    @media (max-width: 768px) {
      left: 10px;
    }
  }
}
