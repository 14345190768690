.bread-crumb {
  margin-left: auto;

  @media (max-width: 768px) {
    display: none;
  }

  .text {
    color: var(--color-main-black);
    font-weight: 700;
    text-transform: lowercase;
  }

  .last-item {
    color: var(--color-main-pink) !important;
    text-transform: lowercase;
  }

  .ant-breadcrumb-link {
    a {
      color: var(--color-main-black);
    }
  }

  .ant-breadcrumb-separator {
    color: var(--color-main-black);
  }
}
