@import 'colors';
@import 'custom-bootstrap';
@import './antd.scss';

@font-face {
  font-family: 'Arson Pro';
  src: url('../assets/fonts/Arson-Pro-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Memesique';
  src:
    url('../assets/fonts/Memesique-Regular.woff2') format('woff2'),
    url('../assets/fonts/Memesique-Regular.otf') format('otf'),
    url('../assets/fonts/Memesique-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Arson Pro', sans-serif;
  height: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.container {
  margin: 0 auto;


  @media (max-width: 1280px) {
    //padding: 0 40px;
  }

  @media (max-width: 576px) {
    //padding: 50px 16px;
  }

  @media (max-width: 768px) {
    padding: 0 !important;
  }
}

.title {
  font-family: 'Memesique', sans-serif;
  color: var(--color-main-black);
  font-style: normal;
  font-size: 70px;
  font-weight: 400;
  line-height: 120%;

  @media (max-width: 480px) {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
  }
}

.text {
  font-family: 'Arson Pro', sans-serif;
  font-size: $text-size;
  color: var(--color-main-black);
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: 480px) {
    font-size: 20px;
  }
}



.custom-input {
  background-color: transparent;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid white;
  color: white !important;
  padding: 5px;
  text-align: left;
}

.ant-carousel .slick-arrow {
  color: #ff1fa5;
}

.slick-dots li button:before {
  display: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;

  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu,
ol,
ul {
  list-style: none;
  list-style-type: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
