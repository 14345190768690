.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .spinner-card {
    padding: 24px;
    border-radius: unset;
    font-size: 48px;
    border: unset;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
      padding: 24px;
    }
  }

  .ant-spin {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
