.recommendation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .text-section {
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    gap: 30px;
  }

  .main-title {
    color: var(--color-main-pink) !important;
    line-height: 90% !important; /* 63px */
    width: 40px;
    
    @media (max-width: 480px) {
      font-size: 50px;
    }
  }

  .subtitle {
    font-size: 50px;

    @media (max-width: 480px) {
      font-size: 30px;
    }
  }

  .description {
    max-width: 400px;
  }

  .image-section {
    .recommendation-image {
      width: 100%;
      height: auto;

      @media (max-width: 1300px) {
        display: none;
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }
  }
}
