.carousel-container {
  padding: 100px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 50px 0;
    gap: 50px;
  }

  .carousel-title {
    font-family: 'Memesique', sans-serif;
    font-size: 70px;
    margin-left: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 50px;
      margin-left: 20px;
    }
  }

  .carousel-image {
    width: 300px;
    margin: 10px;

    @media (max-width: 768px) {
      width: 150px;
    }
  }
}
