.FurnitureCollection {
  display: flex;
  flex-direction: column;

  .flex {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .furniture-card-wrapper {
    flex: 1;
    background-size: cover;
    background-position: center;
    transition:
      flex-basis 0.6s ease-in-out,
      background-color 0.6s ease-in-out;
    background-blend-mode: multiply;

    &.expanded {
      flex-basis: calc(50% + 100px - 10px);
      background-color: rgba(0, 0, 0, 0.3);
    }

    &.collapsed {
      flex-basis: calc(50% - 100px - 10px);
    }

    min-width: 200px;

    &.bg-color-1 {
      background-color: #e2df8f;
    }

    &.bg-color-2 {
      background-color: #d9635e;
    }

    &.bg-color-3 {
      background-color: #d0c3fc;
    }

    &.bg-color-4 {
      background-color: #a2c2f4;
    }

    &.bg-color-5 {
      background-color: #a2c2f4;
    }

    &.bg-color-6 {
      background-color: #add8e6;
    }
  }

  .item {
    background: var(--color-main-pink);
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px 50px 20px 50px;

    @media (max-width: 768px) {
      padding: 85px 20px 20px 20px;

    }



  .all {
    font-family: 'Memesique', sans-serif;
    font-size: 70px;
    font-style: normal;
    line-height: 90%;
    text-transform: uppercase;
    color: var(--color-main-black);
    text-decoration: none;

    &:hover {
      color: var(--color-main-white);
    }

    @media (max-width: 768px) {
      color: var(--color-main-white);
    }
  }

  }
}
