.catalog-button {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;

  @media (max-width: 480px) {
    display: none;
  }

  svg {
    margin-left: 5px;
  }
}
