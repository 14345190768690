.ui-help__mouse {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 56px;
  border: 1px solid black;
  border-radius: 17px;
  box-shadow:
          0 0 2px rgba(0, 0, 0, 0.5),
          inset 0 0 2px rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .ui-help__mouse__wheel {
    position: absolute;
    left: 50%;
    top: 13px;
    width: 4px;
    height: 10px;
    background: black;
    border-radius: 2px;
    transform: translateX(-50%);
    animation: mouse-wheel 2.5s infinite ease-in-out;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
}

@keyframes mouse-wheel {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, 12px);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}
