.recommended-chairs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 20px;
    width: unset;
    padding: 0 10px;
  }

  .title {
    color: var(--color-main-pink);
    font-size: 50px;

    @media (max-width: 480px) {
      margin: 0 10px;
    }
  }

  .chairs-grid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 225px;
    margin: 0 auto;

    @media (max-width: 480px) {
      min-height: unset;
    }

    .chair-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition:
        opacity 0.3s ease,
        transform 0.3s ease;
      @media (max-width: 480px) {
        min-width: 100px;
        min-height: 70px;
      }
      .product-card {
        min-height: 50%;
      }

      @media (max-width: 480px) {
        .text {
          display: none;
        }
      }

      .img {
        object-fit: cover;
        width: 300px;
        height: 200px;
        border: 1px solid #eaeaea;
      }

      p {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .pagination {
    width: calc(100% - 50px);
    display: flex;
    justify-content: flex-end;
    gap: 30px;

    @media (max-width: 480px) {
      justify-content: center;
    }

    .catalog-detail-filter-button {
      padding: 13px 40px;

      @media (max-width: 480px) {
        padding: 25px 20px 25px 57px;
        .text {
          display: none;
        }
      }
    }
  }
}
