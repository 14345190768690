.catalog-detail {
  display: flex;
  flex-direction: column;
  gap: 50px;

  min-height: 100vh;

  @media (max-width: 1440px) {
    min-height: 600px;
  }

  @media (max-width: 768px) {
    gap: 0;
  }

  @media (max-width: 768px) {
    .default {
      padding-top: unset;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    gap: 20px;

    @media (max-width: 768px) {
      min-height: 50vh;
    }
  }
}
