.product-card {
  position: relative;
  flex-basis: calc(25%);
  min-height: 260px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  @media (max-width: 1024px) {
    min-height: unset;
    flex-basis: calc(50%);
  }

  @media (max-width: 480px) {
    flex-basis: calc(100%);
  }

  .image-wrapper {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.3s ease;
    }
  }

  .product-name {
    position: absolute;
    bottom: 20px;
    left: 10px;
    color: var(--color-main-black);
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s ease;
    z-index: 2;
  }

  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
    z-index: 1;
  }

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);

    img {
      opacity: 0.7;
    }

    .card-overlay {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .product-name {
      color: var(--color-main-white);
      transform: translateX(20px);
    }
  }
}
