.custom-modal {
  max-width: 837px;
  width: 100% !important;
  .ant-modal-content {
    background-color: var(--color-main-white);
    border-radius: unset;
    padding: 50px 92px;
    margin: 0 auto;

    @media (max-width: 1024px) {
      margin: unset;
      gap: 30px;
      padding: 50px;
    }

    @media (max-width: 768px) {
      margin: unset;
      //padding: 20px 10px;
    }
  }

  .ant-form-item {
    width: 100%;
    margin-bottom: unset;
  }

  .custom-close-icon {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }

  .modal-content {
    text-align: center;
    max-width: 561px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 50px;
    flex-direction: column;
    align-items: center;

    .text {
      p {
        color: var(--color-main-black);
      }

      span {
        color: var(--color-main-pink);
      }

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    .custom-input {
      border-bottom: 1px solid var(--color-main-black);
      color: var(--color-main-black) !important;
      text-align: center;
    }

    .modal-btn {
      height: 50px;
      padding: 20px 78px;

      @media (max-width: 768px) {
        width: 100%;
        padding: 10px;
      }

      @media (max-width: 480px) {
        .text {
          font-size: 16px;
        }
      }
    }
  }
}
