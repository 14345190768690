.tags-container {
  display: flex;
  flex-direction: column;


  .tag-title {
    color: var(--color-main-pink);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .tag {
    // font-family: "Arson Pro";

    padding: 3px 10px;

    color: var(--color-main-black);
    border-radius: 0;
    background-color: #dcdcdc;

    text-transform: lowercase;
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

  }

  .tag:hover {
    background-color: var(--color-main-pink);
    color: #fff;
  }

  .active {
    background-color: var(--color-main-pink);
    color: #fff;
  }
  
}

