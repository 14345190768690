.slide-container {
  position: relative;
  text-align: left;
  cursor: pointer;
  color: var(--color-main-black);
  overflow: hidden;
  width: 100%;
  height: 100vh;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1440px) {
    height: 700px;
  }

  @media (max-width: 1280px) {
    height: 545px;
  }

  @media (max-width: 1024px) {
    height: 500px;
  }

  @media (max-width: 800px) {
    height: 400px;
  }

  @media (max-width: 768px) {
    height: 670px;

    .ant-image {
      height: 670px !important;
    }
  }

  .slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 768px) {
      height: 100% !important;
    }
  }

  .slide-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    bottom: 50px;
    left: 60px;
    transition: left 0.3s ease-in-out;

    @media (max-width: 768px) {
      left: 20px;
      bottom: 70px;
    }

    .text,
    .title {
      max-width: 650px;

      @media (max-width: 1024px) {
        max-width: 70%;
      }
    }

    .slide-header {
      color: var(--color-main-pink);
      font-size: 70px;
      font-style: normal;
      font-weight: 400;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 50px;
      }
    }

    .slide-title {
      font-size: 100px;
      text-transform: uppercase;

      @media (max-width: 1440px) {
        font-size: 50px;
      }

      @media (max-width: 768px) {
        font-size: 30px;
      }
    }

    .slide-description {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .text-arrow {
    display: flex;
    align-items: center;
    gap: 6px;
    transition: margin-left 0.3s ease;

    &:hover {
      margin-left: 12px;
    }
  }


  &.current-slide.hovered .slide-content {
    left: 17px;
  }
}
