$header-size: 70px;
$title-size: 24px;
$text-size: 20px;

$colors: (
  primary: (),
  alternative: (
    700: #f38c9d,
    800: #ff7171,
    1500: #fe5841,
    1400: #54d988,
    green: #70d397,
    red: #ff5454,
  ),
  main: (
    800: #272d37,
    700: #f9f9f9,
    600: #f3b2bd,
    400: #f8cac5,
    300: #fbb9b0,
    200: #ffdbcf,
    100: #ef6078,
    grey: #c3c3c3,
    white: #fff,
    black: #141414,
    pink: #ff1fa5,
  ),
);

:root {
  @each $colorname, $color in $colors {
    @each $key, $value in $color {
      $keyname: '--color-' + $colorname + '-' + $key;
      #{$keyname}: #{$value};
    }
  }
}
