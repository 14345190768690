.responsive-furniture-card {
  width: 100%;
  cursor: pointer;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: absolute;
    bottom: 20px;
    left: 50px;
    color: #000;

    @media (max-width: 768px) {
      left: 20px;
    }

    .title {
      font-style: normal;
      font-weight: 400;
      line-height: 90%; /* 63px */
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 50px;
      }
    }
  }
}
