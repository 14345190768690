.custom-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: unset;
  padding: 10px 60px;
  border-radius: unset !important;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  border: unset !important;

  .arrow-icon {
    position: absolute;
    color: var(--color-main-white);

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }

  .text {
    color: var(--color-main-white);
    text-align: center;
  }

  &:hover {
    background-color: var(--color-main-white) !important;
    border: unset !important;

    .arrow-icon {
      color: var(--color-main-black) !important;
    }

    .text {
      color: var(--color-main-black) !important;
    }
  }
}
