.recommendation-card {
  .content-wrapper {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    @media (max-width: 1300px) {
      padding: 0 10px;
    }

    @media (max-width: 768px) {
      padding: 0;
      flex-direction: column !important;
    }

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  .recommendation-image {
    flex: 1;
    text-align: center;
    overflow: hidden;

    @media (max-width: 1300px) {
      display: none;
    }

    @media (max-width: 768px) {
      display: flex;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .recommendation-text {
    flex: 1;
    position: relative;

    @media (max-width: 480px) {
      padding: 0 10px;
    }

    .title {
      font-size: 50px;
      margin-bottom: 20px;
    }

    .card-columns {
      display: flex;
      gap: 20px;

      @media (max-width: 480px) {
        flex-direction: column-reverse;
        gap: 10px;
      }

      .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .recommendation-card-item {
      background-color: transparent;
      height: fit-content;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 10px;
      border: 3px solid var(--color-main-grey);
    }

    .button-wrapper {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 60px);
      padding: 10px 30px;
      background: linear-gradient(
        0deg,
        #fff 50%,
        rgba(255, 255, 255, 0) 92.37%
      );
      z-index: 1;
      display: flex;
      justify-content: center;

      &.expanded {
        position: static;
        transform: none;
        background: none;
        margin-top: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .toggle-button {
      max-width: 90%;
      padding: 10px 120px;

      @media (max-width: 480px) {
        padding: 10px 0;
        width: 100%;
      }
    }
  }
}
