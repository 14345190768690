.price-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .price-input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;

    .ant-slider {
      width: 100%;
    }

    span {
      color: var(--color-main-white);
      font-size: 14px;
    }

  }
}
