.catalog {
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 1024px) {
    margin-top: 25px !important;
    gap: 20px;
  }

  .content {
    background-color: var(--color-main-white);
    min-height: calc(100vh - 200px);
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1024px) {
      min-height: 50vh;
    }

    .product-grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 40px;

      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }

    .no-data {
      display: flex;
      justify-content: center;
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin: 40px;

      @media (max-width: 1024px) {
        justify-content: center;
        margin: 20px 20px 50px 20px;
      }

      .page {
        margin: 0 40px;
      }
    }

    .catalog-filter-button {
      padding: 13px 40px;

      @media (max-width: 480px) {
        padding: 25px 50px 25px 50px;
        .text {
          display: none;
        }
      }
    }
  }
}
