.header {
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    //gap: 33px;
    gap: 0;

  }

  .default {
    display: flex;
    justify-content: space-between;
    z-index: 111;
    align-items: center;
    padding: 50px 50px 0 50px;

    @media (max-width: 1280px) {
      padding: 25px 40px 0 25px;
    }

    @media (max-width: 768px) {
      width: unset;
      gap: 20px;
      margin: 0;
      padding: 20px 20px 0 20px;
    }

    &.absolute {
      position: absolute;
      padding: 50px 50px 0 50px;
      left: 0;
      margin: 0 auto;
      right: 0;
      z-index: 111;
      width: unset;


      @media (max-width: 1280px) {
        padding: 20px 40px;
      }

      @media (max-width: 768px) {
        padding: 25px 20px;
      }
    }
  }

  .header-icons {
    cursor: pointer;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 18px;

    .icon {
      font-size: 30px;
      color: var(--color-main-black);

      &:hover {
        color: var(--color-main-pink);
      }
    }
  }
}
